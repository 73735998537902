<template>
  <div>
    <PageHeaderFixed>
      <Breadcrumbs :breadcrumb-items="breadcrumbData" />
      <PageHeaderTitle title="Lithium" subtitle="Supply" />
    </PageHeaderFixed>
    <Container>
      <PageSection>
        <NavigationMultiple
          :type-child="breadcrumbs.child.typeChild"
          :type-menu-data="routePath[0] + capitalize(routePath[1])"
          :type-parent="breadcrumbs.parent"
          :with-return-to-overview="!settings.isOverview"
        />
        <SelectItemPage
          :data-testid="testId"
          :options="suppliers"
          :value="selected"
          :placeholder="settings.selectPlaceholder"
          @change-selected-item="changeSelected"
        />
        <PageTitle
          :subtitle="settings.subTitle"
          :tags="isFetchingData ? [] : settings.tags"
          :title="settings.title"
          class="mt-4"
        >
          <template #actions>
            <div class="flex gap-1 items-center">
              <WeightedUnweightedToggle
                :value="weightedUnweighted"
                @on-change-value="onChangeToggleWeightedUnweighted"
              />
              <WeightedUnweightedHelp />
            </div>
          </template>
          <template v-if="detail?.factbox && !isFetchingData">
            <div
              class="supply-factboxes grid grid-flow-dense gap-x-3 gap-y-6 border-t-2 border-blue-500 pt-3"
              :style="cssVars"
              data-test="factbox"
            >
              <div
                v-for="(factbox, index) in detail.factbox"
                :key="index"
                class="supply-item-factbox flex items-start"
              >
                <VerticalDivider v-if="index > 0" class="mr-3" />
                <FactBoxItem
                  class="text-start min-h-[66px] mr-3"
                  :label="factbox.label"
                  :value="factbox.value"
                  :label-class="factbox.labelClass"
                  :value-class="factbox.valueClass"
                  :show-tooltip="factbox.showTooltip"
                  :tooltip-id="factbox.tooltipId"
                  :tooltip-content="factbox.tooltipContent"
                  :variant="factbox.variant"
                  :data-testid="factbox.dataTestid"
                />
              </div>
            </div>
          </template>
        </PageTitle>
        <slot></slot>
      </PageSection>
    </Container>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useLithiumSupplyGeneralStore } from '~/stores/lithium/supply/general'
import { useLithiumSupplyMinedMineStore } from '~/stores/lithium/supply/mined/mine'
import { useLithiumSupplyMinedOreTypeStore } from '~/stores/lithium/supply/mined/oreType'
import { useLithiumSupplyMinedCompanyStore } from '~/stores/lithium/supply/mined/company'
import { useLithiumSupplyMinedCountryStore } from '~/stores/lithium/supply/mined/country'
import { useLithiumSupplyMinedRegionStore } from '~/stores/lithium/supply/mined/region'
import { useLithiumSupplyChemicalOverviewStore } from '~/stores/lithium/supply/chemical/overview'
import { useLithiumSupplyMinedOverviewStore } from '~/stores/lithium/supply/mined/overview'
import { useLithiumSupplyChemicalCompanyStore } from '~/stores/lithium/supply/chemical/company'
import { useLithiumSupplyChemicalCountryStore } from '~/stores/lithium/supply/chemical/country'
import { useLithiumSupplyChemicalRegionStore } from '~/stores/lithium/supply/chemical/region'
import { useLithiumSupplyChemicalPlantStore } from '~/stores/lithium/supply/chemical/plant'
import { useLithiumSupplyChemicalFeedstockStore } from '~/stores/lithium/supply/chemical/feedstock'
import { useLithiumSupplyChemicalProductStore } from '~/stores/lithium/supply/chemical/product'
import { PROJECT_NAME, ValueType } from '~/constants/general'
import capitalize from '~/utils/capitalize'
import FactBoxItem from '~/components/reports/FactBox/v2/FactBoxItem.vue'
import { useGeneralStore } from '~/stores/general'

const { subTitleData, breadcrumbData, breadCrumbObject, configBreadCrumb } = useBreadCrumb()
const supplyGeneralStore = useLithiumSupplyGeneralStore()
const { searchMined, searchChemical } = storeToRefs(supplyGeneralStore)
const { getMinedSearchData, getChemicalSearchData } = supplyGeneralStore

/* Detail mined stores */
const lithiumSupplyMinedStore = useLithiumSupplyMinedMineStore()
const { mineDetail } = storeToRefs(lithiumSupplyMinedStore)
const supplyMinedOreTypeStore = useLithiumSupplyMinedOreTypeStore()
const { oreTypeDetail } = storeToRefs(supplyMinedOreTypeStore)
const supplyMinedCompanyStore = useLithiumSupplyMinedCompanyStore()
const { companyDetail } = storeToRefs(supplyMinedCompanyStore)
const supplyMinedCountryStore = useLithiumSupplyMinedCountryStore()
const { countryDetail } = storeToRefs(supplyMinedCountryStore)
const supplyMinedRegionStore = useLithiumSupplyMinedRegionStore()
const { regionDetail } = storeToRefs(supplyMinedRegionStore)
/* End detail mined stores */

/* Detail chemical stores */
const supplyChemicalCompanyStore = useLithiumSupplyChemicalCompanyStore()
const { company: companyDetailChemical } = storeToRefs(supplyChemicalCompanyStore)
const supplyChemicalCountryStore = useLithiumSupplyChemicalCountryStore()
const { country: countryDetailChemical } = storeToRefs(supplyChemicalCountryStore)
const supplyChemicalRegionStore = useLithiumSupplyChemicalRegionStore()
const { region: regionDetailChemical } = storeToRefs(supplyChemicalRegionStore)
const supplyChemicalPlantStore = useLithiumSupplyChemicalPlantStore()
const { plantDetails: planDetailChemical } = storeToRefs(supplyChemicalPlantStore)
const supplyChemicalFeedstockStore = useLithiumSupplyChemicalFeedstockStore()
const { feedstock: feedstockDetailChemical } = storeToRefs(supplyChemicalFeedstockStore)
const supplyChemicalProductStore = useLithiumSupplyChemicalProductStore()
const { product: productDetailChemical } = storeToRefs(supplyChemicalProductStore)
/* End detail chemical stores */

const supplyMinedOverviewStore = useLithiumSupplyMinedOverviewStore()
const {
  factboxOverviewMine,
  factboxOverviewCompany,
  factboxOverviewCountry,
  factboxOverviewRegion,
  factboxOverviewOreType,
} = storeToRefs(supplyMinedOverviewStore)

const supplyChemicalOverviewStore = useLithiumSupplyChemicalOverviewStore()
const {
  factboxOverviewCompany: factboxOverviewChemicalCompany,
  factboxOverviewCountry: factboxOverviewChemicalCountry,
  factboxOverviewRegion: factboxOverviewChemicalRegion,
  factboxOverviewPlant,
  factboxOverviewProduct,
  factboxOverviewFeedstock,
} = storeToRefs(supplyChemicalOverviewStore)

const generalStore = useGeneralStore()
const { setWeightedUnweighted } = generalStore
const { weightedUnweighted } = storeToRefs(generalStore)

const route = useRoute()
const selected = ref()

const changeSelected = (selection) => {
  selected.value = selection
}

const onChangeToggleWeightedUnweighted = (value: ValueType) => {
  setWeightedUnweighted(value)
}

const cssVars = ref({
  '--colums': 'repeat(auto-fit, minmax(200px, max-content))',
  '--rows': 'auto',
})

const SUPPLY_CHILDS = {
  mines: {
    typeChild: 'mines',
    label: 'Mine',
  },
  plants: {
    typeChild: 'plants',
    label: 'Plant',
  },
  companies: {
    typeChild: 'companies',
    label: 'Company',
  },
  countries: {
    typeChild: 'countries',
    label: 'Country',
  },
  regions: {
    typeChild: 'regions',
    label: 'Region',
  },
  'ore-type': {
    typeChild: 'ore-type',
    selectorDatakey: 'oretypes',
    label: 'Ore Type',
  },
  feedstocks: {
    typeChild: 'feedstocks',
    label: 'Feedstock',
  },
  products: {
    typeChild: 'products',
    label: 'Product',
  },
}

const SUPPLY_PARENT_KEYS = {
  mined: 'mined',
  chemical: 'chemical',
}

const routePath = computed(() => route.fullPath.replace(/(^\/|\/$)/g, '').split('/'))

const breadcrumbs = computed(() => {
  const base = [...routePath.value]
  const item = base.pop()
  const child = SUPPLY_CHILDS[base.pop()]
  const parent = SUPPLY_PARENT_KEYS[base.pop()]
  return {
    base,
    item,
    child,
    parent,
  }
})

enum ROUTE_ANATOMY {
  ITEM = 4,
  CHILD = 3,
  PARENT = 2,
}

const searchData = computed(() => {
  return breadcrumbs.value.parent === SUPPLY_PARENT_KEYS.mined
    ? searchMined.value
    : searchChemical.value
})

const suppliers = computed(() => {
  if (!searchData.value) return []
  const child = breadcrumbs.value.child
  const key = child?.selectorDatakey ?? routePath.value[ROUTE_ANATOMY.CHILD]
  return searchData.value[key]
})

const testId = computed(() => {
  return routePath.value.reduce((prev, current, index) => {
    if (index === routePath.value.length - 1) {
      return prev
    }
    return `${prev}-${current}`
  })
})

const setupBreadcrumb = (breadcrumb = {}) => {
  breadCrumbObject.value = breadcrumb
  configBreadCrumb(true)
}

const factboxOverviewDataSource = computed(() => {
  return {
    [SUPPLY_PARENT_KEYS.mined]: {
      companies: factboxOverviewCompany.value,
      countries: factboxOverviewCountry.value,
      regions: factboxOverviewRegion.value,
      mines: factboxOverviewMine.value,
      'ore-type': factboxOverviewOreType.value,
    },
    [SUPPLY_PARENT_KEYS.chemical]: {
      companies: factboxOverviewChemicalCompany.value,
      countries: factboxOverviewChemicalCountry.value,
      regions: factboxOverviewChemicalRegion.value,
      plants: factboxOverviewPlant.value,
      feedstocks: factboxOverviewFeedstock.value,
      products: factboxOverviewProduct.value,
    },
  }
})

const detailItemDataSource = computed(() => {
  return {
    [SUPPLY_PARENT_KEYS.mined]: {
      companies: companyDetail.value,
      countries: countryDetail.value,
      regions: regionDetail.value,
      mines: mineDetail.value,
      'ore-type': oreTypeDetail.value,
    },
    [SUPPLY_PARENT_KEYS.chemical]: {
      companies: companyDetailChemical.value,
      countries: countryDetailChemical.value,
      regions: regionDetailChemical.value,
      plants: planDetailChemical.value,
      feedstocks: feedstockDetailChemical.value,
      products: productDetailChemical.value,
    },
  }
})

const detail = computed(() => {
  const routeChildKey = routePath.value[ROUTE_ANATOMY.CHILD]
  const routeParentKey = routePath.value[ROUTE_ANATOMY.PARENT]
  if (routePath.value[ROUTE_ANATOMY.ITEM] === 'overview') {
    setupBreadcrumb({
      prevItems: [
        ...breadcrumbs.value.base,
        breadcrumbs.value.parent,
        breadcrumbs.value.child,
        breadcrumbs.value.item,
      ],
    })

    return { factbox: factboxOverviewDataSource.value[routeParentKey][routeChildKey] }
  }

  const detailDataSource = detailItemDataSource.value[routeParentKey][routeChildKey]

  if (!detailDataSource) return {}

  if (detailDataSource.breadcrumb) {
    setupBreadcrumb(detailDataSource.breadcrumb)
  }

  return detailDataSource
})

const { pending: isFetchingData } = useAsyncData(async () => {
  if (breadcrumbs.value.parent === SUPPLY_PARENT_KEYS.mined) {
    await getMinedSearchData()
  }
  if (breadcrumbs.value.parent === SUPPLY_PARENT_KEYS.chemical) {
    await getChemicalSearchData()
  }

  selected.value = (suppliers.value || []).find(
    (item: { id: string }) => item.id === route.params.id,
  )
})

const settings = computed(() => {
  const { item } = breadcrumbs.value
  const isOverview = item === 'overview'

  return {
    isOverview,
    title: isOverview ? `Global ${breadcrumbs.value.child.label}` : selected.value?.name,
    subTitle: isOverview ? capitalize(breadcrumbs.value.item) : '',
    tags: subTitleData.value,
    selectPlaceholder: `Search ${breadcrumbs.value.child.label}`,
  }
})

watch(
  () => route.fullPath,
  () => {
    selected.value = (suppliers.value || []).find(
      (item: { id: string }) => item.id === route.params.id,
    )
  },
)

useHead({
  titleTemplate: () => {
    const { base, item, child, parent } = breadcrumbs.value
    const normalizedCrumbsNames = base.map((crumb) => capitalize(crumb))
    const isOverview = item === 'overview'
    const normalizedItemName = capitalize(item.replaceAll('-', ' '), true)
    return `${PROJECT_NAME} ${normalizedCrumbsNames.join(' | ')} | ${capitalize(
      parent,
    )} | ${capitalize(child.label, true)}${isOverview ? ' ' : ': '} ${normalizedItemName}`
  },
})
</script>

<style scoped>
.supply-factboxes {
  grid-template-columns: 1fr;
  grid-template-rows: var(--rows);
}

@media (width >= 1024px) {
  .supply-factboxes {
    grid-template-columns: var(--colums);
  }
}

@media (width >= 768px) and (width <= 1023px) {
  .supply-factboxes {
    grid-template-columns: repeat(auto-fit, minmax(0, 200px));
  }
}

:deep(.chemical-company-products-list > ul) {
  @apply grid-cols-1;
}

:deep(.chemical-country-products-list > ul) {
  @apply grid-cols-1;
}

:deep(.chemical-region-products-list > ul) {
  @apply grid-cols-1;
}
</style>
