<template>
  <div class="select-wrapper" :data-testid="`container-select-${dataTestid}`">
    <Multiselect
      ref="multiselect"
      :model-value="value"
      :data-testid="`multiselect-${dataTestid}`"
      :class="{ open: isMultiselectOpen, 'item-selected': isSelectHasValue }"
      :options="options"
      label="name"
      :placeholder="placeholder"
      track-by="name"
      :clear-on-select="false"
      :taggable="false"
      :searchable="true"
      :max-height="300"
      :show-labels="false"
      open-direction="bottom"
      @search-change="onSearchChange"
      @update:model-value="onInput"
      @open="dropdownOpen"
      @close="dropdownClose"
    >
      <template #noOptions>Loading ...</template>
      <template #caret>
        <div class="clear-select" :class="clearClass">
          <XCircle
            v-if="isClearIcon"
            class="hover:text-p-yellow-400 text-white"
            :data-testid="`multiselect-clear-icon-${dataTestid}`"
          />
          <ChevronDown
            v-else-if="!isMultiselectOpen"
            class="h-8 w-8 text-white"
            :data-testid="`multiselect-open-icon-${dataTestid}`"
            @click="openMultiSelect"
          />
          <button v-else type="button" class="rotate-180 transform" @click="closeMultiSelect">
            <ChevronDown
              class="h-8 w-8 text-white"
              :data-testid="`multiselect-close-icon-${dataTestid}`"
            />
          </button>
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'nuxt/app'
import { Multiselect } from 'vue-multiselect'
import ChevronDown from '~/assets/icons/ChevronDown.svg'
import XCircle from '~/assets/icons/x-circle.svg'

const props = withDefaults(
  defineProps<{
    value?: any
    options?: any[]
    placeholder?: string
    dataTestid: string
  }>(),
  {
    value: null,
    options: () => [],
    placeholder: 'Search',
  },
)

const emit = defineEmits<{
  changeSelectedItem: [value: any]
}>()

const vueInstance = getCurrentInstance()
const router = useRouter()
const route = useRoute()
const search = ref('')
const isMultiselectOpen = ref(false)

const isSelectHasValue = computed(() => {
  return props.value !== null
})

const isClearIcon = computed(() => {
  return isSelectHasValue.value || search.value !== ''
})

const clearClass = computed(() => {
  if (isClearIcon.value) return 'clear'

  if (!isMultiselectOpen.value) return 'chevronDown'

  return 'chevronUp'
})

const makePath = (idItem) => {
  const path = route.fullPath
  const pathArray = path.split('/')
  pathArray.splice(-2)
  return `${pathArray.join('/')}/${idItem}/`
}

const onSearchChange = (value) => {
  search.value = value
}

const onInput = (selectedItem) => {
  if (!selectedItem) return
  emit('changeSelectedItem', selectedItem)
  router.push(makePath(selectedItem.id))
}

const dropdownOpen = () => {
  isMultiselectOpen.value = true
}

const dropdownClose = () => {
  isMultiselectOpen.value = false
}

const openMultiSelect = () => {
  vueInstance.refs.multiselect.activate()
}

const closeMultiSelect = () => {
  vueInstance.refs.multiselect.deactivate()
}
</script>

<style>
.select-wrapper {
  @apply relative h-full;
}

.select-wrapper .multiselect.open::before {
  @apply bg-p-yellow-900 absolute bottom-2 left-[26px] right-[26px] z-10 h-0.5;

  content: '';
}

.select-wrapper .multiselect.item-selected:not(.open) .multiselect__input {
  @apply placeholder-transparent !important;
}

.select-wrapper .multiselect:not(.item-selected) .multiselect__placeholder {
  @apply hidden;
}

.select-wrapper .multiselect {
  @apply rounded-xl;
}

.select-wrapper .multiselect__input:focus {
  box-shadow: none;
}

.select-wrapper .multiselect__tags {
  @apply flex h-[68px] items-center p-0 !pb-0 pl-16;

  background: linear-gradient(0deg, rgb(255 255 255 / 15%), rgb(255 255 255 / 15%)), #071830;
}

.select-wrapper .multiselect--active .multiselect__select {
  transform: none !important;
}

.select-wrapper .multiselect--active {
  @apply z-10;
}

.select-wrapper .multiselect__select {
  @apply h-[68px];
}

.select-wrapper .multiselect__select::before {
  @apply hidden;
}

.select-wrapper .clear-select {
  @apply absolute z-10 h-8 w-8 cursor-pointer border-none;

  top: 18px;
  right: 21px;
}

.select-wrapper .multiselect__placeholder {
  @apply mb-0 pb-0 pt-0 text-xl font-semibold leading-[48px] text-white;
}

.select-wrapper .multiselect__option {
  @apply hover:bg-p-blue-50 w-full rounded-xl pl-[41px] text-base font-semibold leading-[22px] text-white;
}

.select-wrapper .multiselect__option:hover {
  @apply text-base font-semibold leading-[22px];

  background: linear-gradient(0deg, rgb(255 255 255 / 9%), rgb(255 255 255 / 9%)), #071830 !important;
}

.select-wrapper .multiselect__single {
  @apply mb-0 overflow-hidden text-ellipsis whitespace-nowrap bg-transparent p-0 text-xl font-semibold leading-[26px] text-white !important;

  width: calc(100% - 80px) !important;
}

.select-wrapper .multiselect__input {
  @apply mb-0 rounded-none bg-transparent p-0 text-xl font-semibold leading-[26px];

  width: calc(100% - 80px) !important;
}

.select-wrapper .multiselect__input,
.select-wrapper .multiselect__input::placeholder {
  @apply text-xl font-semibold text-white !important;
}

.select-wrapper .multiselect__content-wrapper {
  @apply z-10 border-[#2C3B4F] bg-[#2C3B4F] pr-10;
}

.select-wrapper .multiselect__content {
  padding-left: 24px;
}

.select-wrapper .multiselect__option--highlight,
.select-wrapper .multiselect__option--selected {
  @apply flex h-[42px] w-full items-center py-[10px] pl-[41px] text-base leading-[22px] text-white !important;

  background: linear-gradient(0deg, rgb(255 255 255 / 9%), rgb(255 255 255 / 9%)), #071830 !important;
}

.select-wrapper .multiselect__element {
  @apply my-1 flex h-[42px] items-center text-xl leading-[48px] text-white !important;
}

.select-wrapper .multiselect .multiselect__tags::before {
  @apply absolute z-10;

  background-color: #fff;
  content: '';
  top: 21px;
  left: 24px;
  width: 24px;
  height: 24px;
  mask-image: url('~/assets/icons/search.svg');
}
</style>
